// Team
// ----
.team {
  .bg-dark-blue {
    h2 {
      margin-bottom: 0;
    }
  }

  .team-section {
    display: none;

    &.active {
      display: block;
    }
  }
}

.team-container {
  .row-container {
    padding: 80px 100px;

    @include breakpoint(medium down) {
      padding: 40px 0;
    }
  }

  .team-member {
    display: flex;
    margin-bottom: 80px;
    color: $xdkblue;

    @include breakpoint(medium down) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .bio-image {
      max-width: 224px;

      img {
        max-width: 224px;
      }

      @include breakpoint(medium down) {
        margin-bottom: 20px;
      }
    }

    @include breakpoint(large) {
      &:nth-last-child(-n+2) {
        margin-bottom: 0;
      }
    }

    .bio-info {
      margin-left: 20px;

      @include breakpoint(medium down) {
        width: 224px;
        margin: 0;
      }

      .fullname {
        margin: 0;
      }
    }
  }

  .contact-info {
    margin: 0;
    list-style-type: none;
  }
}

.board-container {
  .row-container {
    padding: 80px 100px;

    @include breakpoint(medium down) {
      padding: 40px 0;
    }
  }

  .board-member {
    display: flex;
    margin-bottom: 50px;
    color: $xdkblue;

    @include breakpoint(medium down) {
      &:last-child {
        margin-bottom: 0;
      }
    }

    .bio-info {
      margin-left: 20px;

      @include breakpoint(medium down) {
        margin: 0;
      }

      span {
        max-width: 90%;
        display: block !important;

        @include breakpoint (medium down) {
          width: 224px;
          margin: 0 auto;
        }
      }
    }
  }
}