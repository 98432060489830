// Hero
// ----
.hero {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 0;
  //background-attachment: fixed;
  background-attachment: absolute;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  transition: 0s $cubic-bezier;
  transform: translate3d(0, 0, 0);
  transition-property: background-position;
  z-index: 0;

  @include breakpoint (medium down) {
    height: 64vh;
    padding-top: 64px;
  }

  @include breakpoint(large) {
    background-attachment: scroll;
    height: 800px; // TODO: use vh units
    min-height: 100%;
    padding-top: 144px;

    &.short {
      height: 400px;
    }
  }

  h1 {
    line-height: 1;
    max-width: 1170px;

    @include breakpoint(medium down) {
      padding: 0 40px;
    }
  }

  h1, p {
    color: $white;
  }

  .cell {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .material-icons {
    font-size: 30px;
    line-height: 1.2em;
    text-align: center;

    @include breakpoint(large) {
      font-size: 95px;
      font-weight: 100;
    }
  }

  .chevron-down {
    width: 40px;
    height: 40px;
    display: block;
    margin-top: 40px;
    border-right: 2px solid $white;
    border-bottom: 2px solid $white;
    transform: rotate(45deg);
  }
}

.about,
.companies {
  .hero {
    h1 {
      @include breakpoint (large) {
        max-width: 540px;
      }
    }
  }
}