// Tab Panel
// ---------
.tabs-panel {
  padding: 0;

  h2 {
    margin-bottom: 80px;

    @include breakpoint(medium down) {
      margin-bottom: 40px;
    }
  }
}