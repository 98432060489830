// CTA
// ---
.cta {
  h2 {
    margin-bottom: 40px;
  }
  .button {
    @include breakpoint(large) {
      &:first-child {
        margin-right: 15px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    @include breakpoint(medium down) {
      width: 100%;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 5px;
      }
    }
  }
}