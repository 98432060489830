// Companies
// ---------
.companies {
  .hero {
    background-position: right 0;
  }
}

.company {
  width: 100%;
  height: 380px;
  display: flex;
  position: relative;
  padding: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: space-between;

  .company-name {
    @include cubic-bezier;
    width: 100%;
    display: block;
    color: $xdkblue;
    font-family: $kepler-std-display;
    text-align: center;
    line-height: 1;

    @include breakpoint (large) {
      font-size: 40px;
    }

    @include breakpoint (medium down) {
      font-size: 1.875rem;
      line-height: 1.4;
    }
  }

  .company-location {
    @include cubic-bezier;
    width: 100%;
    display: block;
    position: absolute;
    bottom: 60px;
    color: $xdkblue;
    font-weight: bold;
    text-align: center;

    @include breakpoint (medium down) {
      position: static;
      margin-top: 20px;
    }
  }

  &:hover {
    @include breakpoint (large) {
      .company-name,
      .company-location {
        color: $orange;
      }
    }
  }
}

@include breakpoint (medium down) {
  .companies {
    .hero {
      background-position: right 0;

      .cell {
        margin: 0;
      }
    }
    .company-row {
      &:nth-child(odd) {
        .company-background {
          &:nth-child(1) {
            background-color: $light-gray;
          }

          &:nth-child(2) {
            background-color: $white;
          }

          &:nth-child(3) {
            background-color: $light-gray;
          }
        }
      }

      &:nth-child(even) {
        .company-background {
          &:nth-child(1) {
            background-color: $white;
          }

          &:nth-child(2) {
            background-color: $light-gray;
          }

          &:nth-child(3) {
            background-color: $white;
          }
        }
      }

    }
  }
}