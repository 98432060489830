// Nav
// ---
.no-js {
  @include breakpoint(medium down) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(large) {
    .title-bar {
      display: none;
    }
  }
}

#top-nav {
  width: 100%;
  position: absolute;
  z-index: 1000;

  .grid-container {
    @include breakpoint (medium down) {
      padding: 0;
    }
  }

  .title-bar {
    background: $dkblue;
    width: 100%;
    height: 64px;
    justify-content: space-between;
    display: flex;
    position: absolute;
    margin: 0;
    padding: 0;
    z-index: 1;

    @include breakpoint(medium down) {
      padding: 10px 30px;

      .brand-logo {
        height: 30px;

        img {
          height: 30px;
        }
      }
    }

    @include breakpoint(large) {
      background: transparent;
      height: 144px;
    }
  }

  .top-bar {
    background: transparent;
    width: 100%;
    padding: 0;
    $topbar-transition-speed: 264ms;
    @include cubic-bezier;

    @include breakpoint(large) {
      width: auto;
      height: 144px;
    }

    ul {
      background: transparent;
    }
  }

  #main-nav {
    background-color: rgba(3, 8, 37, 0.8);
    padding: 0;

    @include breakpoint(medium down) {
      margin-top: 64px;
    }

    @include breakpoint(large) {
      background-color: transparent;
    }

    li {
      border-bottom: 1px solid rgba(255, 255, 255, 0.33);
      position: relative;
      margin: 0 1rem;

      &:last-child {
        border-bottom: none;
      }

      a {
        color: $white;
        padding: 0.7rem 0;

        @include breakpoint(medium down) {
          height: 50px;
          padding: 12px 30px;
          line-height: 1.6;
        }
      }

      @include breakpoint(medium down) {
        &.active {
          > a {
            background-color: transparent !important;
          }
        }
      }

      @include breakpoint(large) {
        border-bottom: none;

        &:last-child {
          a {
            margin-left: 0.5rem;
            padding: 0.7rem 1rem;
            border: 1px solid $white;
            border-radius: $global-radius;
          }

          &:hover {
            a {
              background-color: $white;
              color: $xdkblue;
            }
          }
        }

        .color-band {
          background: $white;
          width: 0;
          height: 1px;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 1;
          transform: scaleX(0);
          transform-origin: center;
          transition: transform 225ms $cubic-bezier;
        }

        &:hover {
          .color-band {
            width: 100%;
            transform: scaleX(1);
            transition: transform 225ms $cubic-bezier;
          }

          &:last-child {
            .color-band {
              display: none;
            }
          }
        }

        &.active {
          > a {
            background-color: transparent;
          }

          .color-band {
            width: 100%;
            transform: scaleX(1);
          }

          &:last-child {
            a {
              background-color: $white;
              color: $xdkblue;
            }

            .color-band {
              display: none;
            }
          }
        }
      }
    }
  }
}

.nav-color {
  @include breakpoint(large) {
    background-color: rgba(35, 51, 142, 1);
    background-blend-mode: multiply;
    mix-blend-mode: multiply;
    width: 100%;
    height: 144px;
    position: absolute;
    z-index: 999;
    top: 0;
  }
}