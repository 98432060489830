// Intro
// -----
.intro {
  .intro-text {
    display: block;
    margin-bottom: 20px;
    font-size: 20px;

    @include breakpoint(medium down) {
      font-size: 16px;
    }
  }
  .button {
    @include breakpoint(medium down) {
      width: 100%;
    }
  }
}