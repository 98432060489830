// Common
// ------
*:focus {
  outline: none;
}

section,
article {
  padding: 40px;

  &.pad-xl {
    padding: 80px 30px;
  }

  @include breakpoint(large) {
    padding: 80px 0.5em;
  }
}
.hidden {
  display: none !important;
}
.marg-top {
  margin-top: 40px;
}
.marg-bottom {
  margin-bottom: 40px;
}
.no-marg {
  margin: 0;
}
.no-pad {
  padding: 0;
}
hr {
  border-color: $orange;
}
span.text-center {
  display: block;
}
a {
  color: $orange;
}

// Animation Behaviors
// -------------------
$cubic-bezier: cubic-bezier(0.4, 0.0, 0.2, 1);

@mixin cubic-bezier {
  -webkit-transition: all 225ms cubic-bezier(0.4, 0.0, 0.2, 1);
  transition: all 225ms cubic-bezier(0.4, 0.0, 0.2, 1);
}
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}
@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

// Links
// -----
a {
  @include cubic-bezier;

  .material-icons {
    display: inline-block;
    font-size: 34px;
    vertical-align: middle;
    @include cubic-bezier;
  }
}

// Buttons
// -------
.button {
  @include cubic-bezier;
  margin: 0;
  padding: 14px 20px;
  font-weight: 600;

  &:hover {
    background-color: $dkblue;
    opacity: 1;
  }
}

// Google Material Icons
// ---------------------
/* @font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fonts/MaterialIcons-Regular.eot); // For IE6-8
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url(/assets/fonts/MaterialIcons-Regular.woff2) format('woff2'),
       url(/assets/fonts/MaterialIcons-Regular.woff) format('woff'),
       url(/assets/fonts/MaterialIcons-Regular.ttf) format('truetype');
} */

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

// Typography
// ----------
.eyebrow {
  display: block;
  margin-bottom: 15px !important;
  color: $orange;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 16px !important;
}
.sub-title {
  display: block;
  margin-bottom: 2em;
  font-size: 24px;
}

.text-bold { font-weight: bold; }
.text-italic { font-style: italic; }

h1 { line-height: 1; }
h2, h3 { line-height: 1.25; }

// Colors
// ------
.bg-white {
  background-color: $white;
}
.bg-light-gray {
  background-color: $light-gray;
}
.bg-blue {
  background-color: $blue;
  font-size: 20px;

  @include breakpoint(medium down) {
    font-size: 16px;
  }

  * {
    color: $white;
  }
}
.bg-dark-blue {
  background-color: $xdkblue;

  * {
    color: $white;
  }
}
.bg-peach {
  background-color: $peach;
  background-image: url('../img/img_line_pattern.png');
  background-size: cover;

  &.no-img {
    background-image: none;
  }
}

// Mobile
// ------
.grid-container {
  @include breakpoint(medium down) {
    padding: 0;
  }
}