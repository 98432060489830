// News
// ----
.news-wrapper {
  @include breakpoint(medium down) {
    order: 1;

    h2 {
      margin-bottom: 0;
    }
  }
}
.media-inquiry {
  @include breakpoint(medium down) {
    margin-bottom: 40px;
    order: 2;
  }
}

.news-listing {
  list-style-type: none;
  margin: 0;

  li {
    padding: 60px 0;
    border-bottom: 1px solid $orange;

    &:last-child {
      border-bottom: none;
    }

    @include breakpoint(medium down) {
     padding: 30px 0;
    }

    span {
      display: block;
      color: $dkblue !important;
      font-size: 20px;
    }
  }
}

.article-body {
  position: relative;

  a {
    color: $orange;
  }

  blockquote {
    max-width: 700px;
    display: block;
    position: relative;
    float: right;
    margin: 20px -20% 20px 50px;
    padding: 20px 0;
    border-top: 1px solid $orange;
    border-bottom: 1px solid $orange;
    border-left: none;

    @include breakpoint(medium down) {
      margin: 20px 0 40px 0;
    }

    span {
      max-width: 540px;
      display: block;
      color: $xdkblue;
    }

    .quote {
      font-size: 32px;
      line-height: 1.25;
      margin-bottom: 20px;

      &:before {
        content: '“';
        position: absolute;
        left: -15px;
      }

      &:after {
        content: '”';
      }
    }
  }

  @include breakpoint(medium down) {
    .photo-insert {
      display: block;
      margin: 40px 0;
    }
  }

  hr:last-child {
    margin: 40px 0;
  }
}
