// Footer
// ------
footer {
  background: $xdkblue;
  padding: 30px;
  color: $white;

  @include breakpoint(large) {
    padding: 80px 0;
  }

  a {
    color: $white;
  }
}