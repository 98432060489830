// About
// -----
.about {
  -webkit-transform:translate3d(0,0,0);
}

.about .intro-text {
  font-size: 20px;

  @include breakpoint(medium down) {
    font-size: 16px;
    text-align: center;
  }

  p:last-child {
    margin-bottom: 40px;
  }
}

.icon-box {
  max-height: 480px;
  padding: 60px 50px;

  img {
    display: inline-block;
    margin-bottom: 30px;
  }
}

.column-list {
  -moz-column-count: 3;
  -moz-column-gap: 30px;
  -webkit-column-count: 3;
  -webkit-column-gap: 30px;
  column-count: 3;
  column-gap: 30px;
  margin-top: 2em;

  li {
    display: block;
    padding-left:2em;
    margin-bottom: 1.5em;
  }

  li:before {
    content:'\2022';
    margin-right:0.5em;
    margin-left: -0.9em;
  }

  @include breakpoint(medium down) {
    -moz-column-count: 1;
    -moz-column-gap: 30px;
    -webkit-column-count: 1;
    -webkit-column-gap: 30px;
    column-count: 1;
    column-gap: 30px;
    margin-top: 1em;
    margin-bottom: 0;

    li {
      padding-left: 0;
    }
  }
}

.second-headline {
  display: block;
  margin-top: 1em;
}

.image-section {
  @include breakpoint (medium down) {
    text-align: center;
  }
}

.approach {
  p {
    font-size: 20px;
    margin-bottom: 0;

    @include breakpoint(medium down) {
      font-size: 16px;
    }
  }

  @include breakpoint (medium down) {
    h2:last-child {
      margin-bottom: 0;
    }
  }
}

.card-grid {
  @include breakpoint (medium down) {
    .bg-dark-blue {
      background-color: transparent;

      h2 {
        color: $xdkblue;
        font-family: $source-sans-pro;
        font-size: 16px;
        font-weight: bold;
      }
    }

    .bg-light-gray {
      background-color: transparent;
      border: none;
    }

    .card-front {
      padding-top: 40px;
    }

    .card-back {
      padding-bottom: 40px;
      border-bottom: 1px solid $orange;
    }

    .card-flip:last-child {
      .card-back {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }

  @include breakpoint (large) {
    .info-card {
      width: 100%;
      height: 100%;
      padding: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      -webkit-transition: transform 256ms $cubic-bezier;
      transition: transform 256ms $cubic-bezier;
    }

    .card-front, .card-back {
      -webkit-perspective: 1000;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }

    .card-front {
      -webkit-transform: rotateY(0);
      transform: rotateY(0);
      z-index: 1;
    }

    .card-back {
      font-size: 22px;
      border: 1px solid $medium-gray;
      -webkit-transform: rotateY(-180deg);
      transform: rotateY(-180deg);
      z-index: 0;

      p {
        margin-bottom: 0;
      }
    }

    .card-flip {
      min-height: 340px;
      margin-bottom: 2rem;
      position: relative;
      overflow: visible;

      &:hover {
        .card-front {
          -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
          z-index: 1;
        }

        .card-back {
          -webkit-transform: rotateY(0);
          transform: rotateY(0);
          z-index: 2;
        }
      }
    }
  }
}

.icon-grid {
  img {
    margin-bottom: 20px;
  }
}