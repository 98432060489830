// Modal
// -----
.reveal {
  width: 100%;
  max-width: 1185px;
  padding: 100px;

  @include breakpoint (medium down) {
    padding: 30px;
  }

  &.fast {
    transition-duration: 225ms !important;
  }

  @include breakpoint(large) {
    &.no-logo {
      width: 50%;
      max-width: 768px;

      .large-7 {
        width: 100%;
      }
    }
  }

  &.company-modal,
  &.team-member-modal {
    .company-name,
    .team-member-name {
      display: block;
      margin-bottom: 5px;
      color: #1d2447;
      font-family: "kepler-std-display", "Times New Roman", Times, serif;
      font-size: 40px;
      line-height: 1;
    }

    .team-member-quote {
      position: relative;
      margin-top: 40px;
      padding: 20px 0;
      border-top: 1px solid $orange;

      .quote-text {
        display: block;
        font-size: 32px;
        line-height: 1.25;
        margin-bottom: 20px;

        &:before {
          content: '“';
          position: absolute;
          left: -15px;
        }

        &:after {
          content: '”';
        }
      }

      .quote-attrib-primary,
      .quote-attrib-secondary {
        font-size: 20px;
      }
    }
  }

  > ul {
    span {
      margin-left: -20px;
    }
  }

  hr {
    margin: 45px 0;
  }

  .company-logo {
    @include breakpoint(medium down) {
      margin-bottom: 40px;
    }
  }

  .team-member-title {
    font-size: 24px;
  }

  .contact-info {
    margin: 15px 0 0 0;
    list-style-type: none;

    @include breakpoint (medium down) {
      margin-bottom:30px;
    }
  }

  .section-title {
    display: block;
    color: $xdkblue;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .website-link {
    display: inline-block;
    margin-top: 20px;
    padding: 15px 0;
    border-bottom: 2px solid $blue;
    color: $xdkblue;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
}