// Tab Nav
// -------
.tab-nav {
  .tabs {
    background: transparent;
    display: flex;
    justify-content: center;
    border: none;

    &.mobile-invis {
      @include breakpoint(medium down) {
        height: 0;
        visibility: hidden;

        * {
          height: 0;
          margin: 0;
        }
      }
    }

    @include breakpoint (medium down) {
      display: block;
      text-align: center;
    }

    li {
      display: inline-block;

      @include breakpoint (medium down) {
        width: 100%;
        display: block;
        margin-bottom: 10px;
      }

      a {
        margin: 0 20px;
        padding: 15px 0;
        border-bottom: 2px solid transparent;
        color: $xdkblue;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 2px;

        &:hover, &:focus, &[aria-selected='true'] {
          background-color: transparent;
          border-bottom: 2px solid $blue;
        }

        @include breakpoint (medium down) {
          text-align: center;
        }
      }
    }
  }

  select {
    margin-bottom: 0;

    @include breakpoint(large) {
      display: none;
    }
  }
}