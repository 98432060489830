// Contact
// -------
.form-wrapper {
  @include breakpoint(medium down) {
    margin-bottom: 40px;
  }
}

.location-info {
  margin-bottom: 40px;
}

#googlemap {
  height: 240px;
}