// Contact Form
// ------------
.contact-form {
  .form-set {
    label {
      display: none;
    }

    input {
      background-color: $light-gray;
      height: auto !important;
      border: 0;
      box-shadow: none;
      padding: 20px;
      font-size: 16px;
    }
    textarea {
      min-height: 150px;
      background-color: $light-gray;
      border: 0;
      box-shadow: none;
      padding: 20px;
      font-size: 16px;
      resize: vertical;
    }

    input::placeholder,
    textarea::placeholder {
      color: $xdkblue;
      text-transform: uppercase;
      letter-spacing: 2px;
      opacity: 1;
    }
  }

  .form-messages {
    display: inline-block;
    margin-left: 20px;

    &.success {
      color: $success-color;
    }

    &.error {
      color: $alert-color;
    }
  }
}